<template>
  <div class="control-container">
    <a-range-picker
      style="width: 230px"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="date"
      :allowClear="false"
      :disabledDate="disabledDate"
    />
    <div class="indicator">
      <a-select :value="i1" style="width: 120px; margin-right: 10px" @change="selectIndicator($event, 1)">
        <a-select-option v-for="(item, i) in targetList" :key="i" :disabled="i2 === item.value" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
      <a-select :value="i2" style="width: 120px" @change="selectIndicator($event, 2)">
        <a-select-option v-for="(item, i) in targetList" :key="i" :disabled="i1 === item.value" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getMediaReportRuleVo } from '@/api/reportdatas'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate],
  model: {
    prop: 'time',
    event: 'changeTime'
  },
  data () {
    return {
      date: [],
      targetList: [],
      allList: [
        { label: 'DAU', value: 'dau', color: '#ffab31' },
        { label: '流量请求', value: 'request', color: 'rgb(76, 180, 231)' },
        { label: '流量填充', value: 'response', color: '#de7af9' },
        { label: '流量填充率', value: 'requestFilledRate', color: 'rgb(0, 118, 143)' },
        { label: '展示', value: 'unitImpression', color: '#ff746c' },
        { label: '点击', value: 'unitClick', color: '#39da61' },
        { label: '点击率', value: 'unitClickRate', color: '#ff6688' },
        { label: 'eCPM', value: 'unitEcpm', color: 'rgb(145, 78, 0)' },
        { label: '收益', value: 'mediaUnitRevenue', color: '#0055ff' },
        { label: '人均收益', value: 'arpu', color: 'rgb(255, 192, 159)' }
      ]
    }
  },
  props: {
    time: {
      type: Array,
      default: () => []
    },
    indicator1: {
      type: String,
      default: ''
    },
    indicator2: {
      type: String,
      default: ''
    }
  },
  computed: {
    i1 () {
      return this.indicator1
    },
    i2 () {
      return this.indicator2
    }
  },
  watch: {
    time: {
      handler (val) {
        this.date = [...val]
      }
    }
  },
  mounted () {
    this.getMediaReportRuleVo()
  },

  methods: {
    async getMediaReportRuleVo () {
      const resp = await getMediaReportRuleVo()
      const custTags = resp.data ? resp.data.data : []
      this.targetList = this.allList.filter((item) => {
        return custTags.includes(item.value)
      })
      if (this.targetList.length >= 2) {
        this.selectIndicator(this.targetList[0].value, 1)
        this.$nextTick(() => {
          this.selectIndicator(this.targetList[1].value, 2)
        })
      }
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 1).end
    },
    getColor (val) {
      return this.targetList.filter((item) => item.value === val)[0].color
    },
    ChangeDate (date, dateString) {
      this.$emit('changeTime', date)
      this.$emit('changeDate', dateString)
    },
    selectIndicator (val, index) {
      this.$emit('changeIndicator', [val, index])
    }
  }
}
</script>

<style lang="less" scoped>
.control-container {
  padding: 10px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  vertical-align: top;
  .reportdatas-container .ant-calendar-picker-input.ant-input {
    padding: 4px 3px;
    .ant-calendar-range-picker-input {
      font-size: 12px;
      width: 40%;
    }
  }
}
</style>
